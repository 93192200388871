import React, { Component } from 'react';
import './App.less';

import background from './assets/background.png'
import heartBg from './assets/bg-heart.png'
import lightning from './assets/lightning.png'
import horn from './assets/horn.png'
import alarmClock from './assets/alarm-clock.png'
import girl from './assets/girl.png'
import smallHeart1 from './assets/small-heart-1.png'
import smallHeart2 from './assets/small-heart-2.png'
import smallHeart3 from './assets/small-heart-3.png'
import give543Logo from './assets/GIVE543_logo.png'
import bgmFile from './assets/bgm.mp3'
import coolGuy from './assets/cool-guy.png'

import scoreResult1 from './assets/score_result_1.png'
import scoreResult2 from './assets/score_result_2.png'
import scoreResult3 from './assets/score_result_3.png'
import scoreResult4 from './assets/score_result_4.png'

import lipstick1 from './assets/lipstick1.jpg'
import lipstick2 from './assets/lipstick2.jpg'
import lipstick3 from './assets/lipstick3.jpg'

import option1 from './assets/option-1.png'
import option2 from './assets/option-2.png'
import option3 from './assets/option-3.png'

import eyelash1 from './assets/eyelash-1.png'
import eyelash2 from './assets/eyelash-2.png'
import eyelash3 from './assets/eyelash-3.png'

import dialogClose from './assets/dialog_ic_close.png'
// import FbIcon from './assets/facebook-logo.svg'
import iconFB from './assets/icon-fb.png'
import iconLine from './assets/icon-line.png'
import iconLink from './assets/link.png'
import {CopyToClipboard} from 'react-copy-to-clipboard';

import {
  FacebookShareButton,
  LineShareButton,
} from 'react-share';

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      examinations: [
        { // 1
          type: 'radio', // 'radio' 單選 'check' 多選
          question: '另一半最近脾氣有些「暴躁」，情人節送什麼給她最好？',
          direction: 'row',
          options: [
            {
              image: option1, // '姨媽巾',
            },
            {
              image: option2, // '滅火器',
            },
            {
              image: option3, // '禮物',
            }
          ],
          answer: 2,
        },
        { //2
          type: 'radio',
          question: '另一半是口紅控，想趁情人節買幾支哄她開心，下面哪種顏色打死也不能送？',
          direction: 'row',
          options: [
            {
              image: lipstick1,
              text: '斬男色'
            },
            {
              image: lipstick2,
              text: '髒橘色'
            },
            {
              image: lipstick3,
              text: '芭比粉色'
            }
          ],
          answer: 2,
        },
        {
          type: 'radio',
          question: '另一半手受傷了，幫她畫個美美的妝吧，假睫毛的正確黏黏部位是？',
          direction: 'row',
          options: [
            {
              image: eyelash1,
            },
            {
              image: eyelash2,
            },
            {
              image: eyelash3,
            }
          ],
          answer: 0,
        },
        {
          type: 'radio',
          question: '陪另一半逛街，你是怎麼做的？',
          direction: 'column',
          options: [
            '最討厭跟女人逛街了，打死也不去。',
            '怎麼這麼能逛啊，不能快點嗎？',
            '幫提包，耐心給意見。'
          ],
          answer: 2
        }, {
          type: 'radio',
          question: '另一半買了新衣服，問你好不好看，這時你怎麼回答？',
          direction: 'column',
          options: [
            '「妳穿什麼，我都喜歡」',
            '「真好看！這件衣服的版型和顏色太適合你了～ 」',
            '「款式還不錯，最近路上看到很多人穿。」'
          ],
          answer: 1
        }, {
          type: 'radio',
          question: '另一半沒回Line、手機響一聲就被掛斷，你認為最可能的原因是？',
          direction: 'column',
          options: [
            '女友家收訊不好。',
            '最近工作忙，一定在專心加班趕報告。',
            '可能是我又惹到她，在跟我鬧彆扭！'
          ],
          answer: 2
        }, {
          type: 'radio',
          direction: 'column',
          question: '前任送的禮物，留著哪天被女友看到又是一場災難。你會怎麼處理前任的東西？',
          options: [
            '都藏在死黨家，我好機智~',
            '放上《贈物網》送給更合用的人。',
            '我光明正大，怕什麼。'
          ],
          answer: 1,
        }, {
          type: 'radio',
          direction: 'column',
          question: '另一半氣的該該叫，你怎麼做？',
          options: [
            '解釋清楚，不想讓她誤會',
            '默默走開，等她冷靜下來再說',
            '親親，抱抱，舉高高'
          ],
          answer: 2
        }, {
          type: 'radio',
          direction: 'column',
          question: '另一半說：我剛剪了瀏海，好醜啊。以下錯誤的回應是：',
          options: [
            '哪有，跟沒剪的時候沒差啊~',
            '你臉大，這種瀏海不適合你吧~',
            '你醜我也喜歡啊~',
            '以上都是'
          ],
          answer: 3
        }, {
          type: 'radio',
          direction: 'column',
          question: '發現另一半的購物車裡有好多東西，你的態度是？',
          options: [
            '只知道買買買，當不要錢啊~',
            '少買點吧，節約是美德！',
            '我女人，想買就買，開心就好~',
            '清空她的購物車。'
          ],
          answer: 3
        }
      ],

      scoreResult: [
        {
          score: 59,
          image: scoreResult1,
          text: '就你也配有女朋友？！你走！你走！你走！'
        },
        {
          score: 79,
          image: scoreResult2,
          text: "還有進步空間噢，另一半都是愈寵愈可愛的！"
        },
        {
          score: 99,
          image: scoreResult3,
          text: '世界這麼大，怎麼就你這麼優秀？！'
        },
        {
          score: 100,
          image: scoreResult4,
          text: '天啊～你就是傳說中的完美另一半吧！'
        }
      ],
      currentPage: 0,
      bgmPlay: true,
      currentStatus: 'cover', // cover, desc test, result
      score: 0,
      optionIndex: ['A', 'B', 'C', 'D', 'E'],
      colors: ['#fa0d2f', '#0d93fd', '#ff4c0e', '#0dfd51'],
      showShare: false,
      name: '',
      showNameError: false,
      shareUrl: window.location.href,
      shareText: '',
      copied: false
    }

    this.changeBgmStatus = this.changeBgmStatus.bind(this)
    this.nextPage = this.nextPage.bind(this)
    this.toReChallenge = this.toReChallenge.bind(this)
    this.toShowOff = this.toShowOff.bind(this)
    this.closeShareBlock = this.closeShareBlock.bind(this)
    this.onNameChange = this.onNameChange.bind(this)
    this.fbShareOnclick = this.fbShareOnclick.bind(this)
    this.startExam = this.startExam.bind(this)
    this.toDownloadApp = this.toDownloadApp.bind(this)
  }

  // componentDidMount () {
  //   this.getShareUrl()
  // }

  // componentDidMount () {
  //   /* eslint-disable */
  //   window.fbAsyncInit = () => {
	// 		FB.init({
	// 			appId: 281409512031484,
	// 			xfbml: true,
	// 			version: 'v3.2'
	// 		});
	// 	};

	// 	(function(d, s, id){
	// 		var js, fjs = d.getElementsByTagName(s)[0];
	// 		if (d.getElementById(id)) {return;}
	// 		js = d.createElement(s); js.id = id;
	// 		js.src = "//connect.facebook.net/zh_TW/sdk.js";
	// 		fjs.parentNode.insertBefore(js, fjs);
  //   }(document, 'script', 'facebook-jssdk'));
  //   /* eslint-enable */
  // }

  // componentDidUpdate() {
	// 	if (window.FB) {
	// 		window.FB.XFBML.parse();
	// 	}
  // }
  
  fbShareOnclick() {
    /* eslint-disable */
    FB.ui({
      method: 'share',
      display: 'popup',
      href: this.state.shareUrl,
    }, function(response){});
     /* eslint-enable */
	}

  changeBgmStatus () {
    if (this.state.bgmPlay) {
      this.player.pause()
    } else {
      this.player.play()
    }

    this.setState({
      bgmPlay: !this.state.bgmPlay
    })
  }

  nextPage () {
    if (this.state.currentStatus === 'desc' && !this.state.name) {
      this.setState({
        showNameError: true
      })
      return
    }
    let status = ['cover', 'desc', 'test', 'result']
    let index = status.indexOf(this.state.currentStatus)
    if (index !== status.length - 1) {
      this.setState({
        currentStatus: status[index + 1]
      })
    }
  }

  getShareUrl (newScore) {
    let data = {
      description: "",
      image: "https://d2hrw3sjxwwsb1.cloudfront.net/h5/qixi-share.jpg",
      title: `我在贈物網—男友力大考驗中獲得${newScore || this.state.score}分，你也來試一下！`,
      url: window.location.href,
    }
    this.setState({
      shareText: `我在贈物網—男友力大考驗中獲得${newScore || this.state.score}分，你也來試一下！`
    })
    fetch('https://api.give543.com/api/shares', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    }).then(res => res.json())
      .then((res) => {
        if (res) {
          this.setState({
            shareUrl: res.url
          })
        }
      })
  }

  chooseOption(optionIndex) {
    let { currentPage, score, examinations } = this.state
    if (currentPage >= examinations.length - 1) {
      let newScore = score + (examinations[currentPage] && examinations[currentPage].answer === optionIndex ? 10 : 0)
      this.setState({
        currentStatus: 'result',
        score: newScore
      })
      this.getShareUrl(newScore)
      return
    }
    this.setState({
      score: score + (examinations[currentPage].answer === optionIndex ? 10 : 0),
      currentPage: currentPage + 1
    })
  }

  getScoreResultIndex (score) {
    for (let result of this.state.scoreResult) {
      if (score <= result.score) {
        return result
      }
    }
  }

  toReChallenge () {
    this.setState({
      score: 0,
      currentPage: 0,
      currentStatus: 'test'
    })
    window.gtag('event', 'click', {
      'event_category': '再次挑戰',
      'event_label': '再次挑戰',
      'value': 0
    })
  }

  toShowOff () {
    this.setState({
      showShare: true
    })
    window.gtag('event', 'click', {
      'event_category': '去炫耀',
      'event_label': '去炫耀',
      'value': 0
    })
  }

  closeShareBlock() {
    this.setState({
      showShare: false,
      copied: false
    })
  }

  onNameChange (e) {
    this.setState({
      name: e.target.value,
      showNameError: false
    })
  }

  startExam () {
    this.nextPage()

    window.gtag('event', 'click', {
      'event_category': '開始測試',
      'event_label': '開始測試',
      'value': 0
    })
  }

  toDownloadApp () {
    window.open('https://m.give543.com/static/app.html')
    window.gtag('event', 'click', {
      'event_category': '下載APP',
      'event_label': '下載APP',
      'value': 0
    })
  }

  renderQuestionPage(examination, index) {
    let pageIndex = index
    return (
      <div className="main-page background" key={index} style={{ display:  this.state.currentStatus === "test" && pageIndex === this.state.currentPage ? 'block' : 'none' }}>
        <img className="background-img" src={background} alt="backgroud" />
        <div className="pad-question">
          <div className="pad-bg">
            <img className="heart-bg" src={heartBg} alt="bg" />
            <img className="lightning" src={lightning} alt="lightning" />
            <img className="horn" src={horn} alt="horn" />
            <img className="alarm-clock" src={alarmClock} alt="alarm" />
            <img className="girl" src={girl} alt="girl" />
            <img className="small-heart1 heart-scale" src={smallHeart1} alt="smallheart" />
            <img className="small-heart2 heart-scale" src={smallHeart2} alt="smallheart" />
            <img className="small-heart3 heart-scale" src={smallHeart3} alt="smallheart" />
            <div className="text">{examination.question}</div>
          </div>
        </div>
        <div className={"pad-answer " + examination.direction}>
          {
            examination.options.map((option, j) => {
              return (
                <div className="answer-option" key={j} onClick={this.chooseOption.bind(this, j)} >
                  {
                    option.image ? (
                      <div className="option-img">
                        <img src={option.image} alt="option"/>
                        {option.text && <span>{option.text}</span>}
                        <span>{this.state.optionIndex[j]}</span>
                      </div>
                    ) : (
                      <div>
                        <div className="option-text" style={{backgroundColor: this.state.colors[j]}}>{this.state.optionIndex[j]}、{option.text || option}</div>
                      </div>
                    )
                  }
                </div>
              )
            })
          }
        </div>
        <div className="question-index">{index + 1}/10</div>
      </div>
    )
  }

  renderCover () {
    return (
      <div className="main-page background" style={{ display: this.state.currentStatus === "cover" ? "block" : "none"}}>
        <div className="background-img" ></div>
        <div className="pad-cover">
          <img className="cool-guy" src={coolGuy} alt="guy"/>
          <div className="cover-content">
            <div className="examination-title">男友力大考驗</div>
            <div className="sub-title">呼朋引伴，男女不拘，一起來測啦~</div>

            <div className="description">確定過眼神，<br/>你就是對的人，<br/>測就測啊~</div>
            <div className="button-wrap">
              <button className="button" onClick={this.nextPage}>考前須知</button>
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderDescription () {
    return (
      <div className="main-page background" style={{ display: this.state.currentStatus === "desc" ? "block" : "none"}}>
        <img className="background-img" src={background} alt="backgroud" />
        <div className="pad-description">
          <div className="section">
            <div className="title">出題人</div>
            <div>宇宙無敵聰明又可愛的贈物網小編</div>
          </div>
          <div className="section">
            <div className="title">幾點要求</div>
            <div>1. 一氣呵成，不要中途離開</div>
            <div>2. 嚴禁交頭接耳、左顧右盼以及<br/>詢問Google</div>
            <div>3. 不服氣隨時重考</div>
          </div>
          <div className="pad-input-name">
            <span className="desc">請輸入您的姓名或暱稱，開始測試吧~</span>
            <input className="input" maxLength="8" placeholder="請輸入您的姓名或暱稱" onChange={this.onNameChange}/>
            {this.state.showNameError && <span className="error">請先輸入您的姓名或暱稱</span>}
          </div>
          <div className="button-wrap">
            <button className="button" onClick={this.startExam}>開始測試</button>
          </div>
        </div>
      </div>
    )
  }

  renderResultPage () {
    let result = this.getScoreResultIndex(this.state.score)
    return (
      <div className="main-page background" style={{ display: 'block'}}>
        <img className="background-img" src={background} alt="backgroud" />
        <div className="pad-result">
          <div className="pad-score">{this.state.name}<br/>你獲得了<span className="score">{this.state.score}</span>分</div>
          <div className="pad-desc">
            <div className="result-text">{result.text}</div>
            <img className="resut-image" src={result.image} alt="img" />
          </div>
          <div className="buttons">
            {this.state.score !== 100 && <button className="btn" onClick={this.toReChallenge}>不服再戰</button>}
            <button className="btn" onClick={this.toShowOff}>去炫耀</button>
          </div>
          <div className="pad-download">
            <button className="btn" onClick={this.toDownloadApp}>下載贈物網APP</button>
          </div>
        </div>

        { (<div className="pad-share-mask" style={{display: this.state.showShare ? 'block' : 'none'}}>
          <div className="pad-share">
            <div className="close" onClick={this.closeShareBlock}>
              <img src={dialogClose} alt="close" />
            </div>
            <div className="share-text">分享到</div>
            <div className="share-buttons">
              <FacebookShareButton url={this.state.shareUrl} class="share-button">
                <img src={iconFB} alt="fb"/>
                <div class="text">FB</div>
              </FacebookShareButton>
              <LineShareButton url={this.state.shareUrl} class="share-button">
                <img src={iconLine} alt="line"/>
                <div class="text">Line</div>
              </LineShareButton>
              <CopyToClipboard text={`${this.state.shareText} ${this.state.shareUrl}`} onCopy={() => this.setState({copied: true})}>
                <div class="share-button">
                  <img src={iconLink} alt="line"/>
                  <div class="text">{this.state.copied ? '已複製' : '複製鏈接'}</div>
                </div>
              </CopyToClipboard>
              {/* <div className="fb-share-button" data-href={this.state.shareUrl} data-layout="button" data-size="large">
                <a target="_blank" href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fdevelopers.facebook.com%2Fdocs%2Fplugins%2F&amp;src=sdkpreparse" className="fb-xfbml-parse-ignore">分享</a>
              </div> */}
              {/* <img src={FbIcon} id="fb-shareBtn" className="fb-share" onClick={this.fbShareOnclick} alt="fb" /> */}
              {/* <div className="line-it-button" data-lang="zh_Hant" data-type="share-b" data-ver="3" data-url={this.state.shareUrl} data-color="default" data-size="small" data-count="false"></div> */}
            </div>
          </div>
        </div>
        )}
      </div>
    )
  }

  render() {
    return (
      <div>
        <div className="bgm-btn bgm-anim" style={{animationPlayState: this.state.bgmPlay ? "running" : "paused"}} onClick={this.changeBgmStatus}>
          <audio ref={ref => this.player = ref} src={bgmFile} loop autoPlay preload="true"></audio>
        </div>
        <img className="logo" src={give543Logo} alt="logo" />
        {
          this.renderResultPage()
        }
        {
          this.state.examinations.map((examination, index) => {
            return this.renderQuestionPage(examination, index)
          })
        }
        {
          this.renderDescription()
        }
        {
          this.renderCover()
        }
      </div>
    )
  }
}

export default App;
